export const GET_LEGAL_COMPLIANCE_PAGINATION = "[LEGAL_COMPLIANCE] Pagination Get";
export const GET_LEGAL_COMPLIANCE_PAGINATION_SUCCESS = "[LEGAL_COMPLIANCE] Pagination Get Success";
export const GET_LEGAL_COMPLIANCE_PAGINATION_FAIL = "[LEGAL_COMPLIANCE] Pagination Get Fail";

export const GET_LEGAL_COMPLIANCE_COLLECTION = "[LEGAL_COMPLIANCE] Collection Get";
export const GET_LEGAL_COMPLIANCE_COLLECTION_SUCCESS = "[LEGAL_COMPLIANCE] Collection Get Success";
export const GET_LEGAL_COMPLIANCE_COLLECTION_FAIL = "[LEGAL_COMPLIANCE] Collection Get Fail";

export const GET_LEGAL_COMPLIANCE_SINGLE = "[LEGAL_COMPLIANCE] Single Get";
export const GET_LEGAL_COMPLIANCE_SINGLE_SUCCESS = "[LEGAL_COMPLIANCE] Single Get Success";
export const GET_LEGAL_COMPLIANCE_SINGLE_FAIL = "[LEGAL_COMPLIANCE] Single Get Fail";

export const CREATE_LEGAL_COMPLIANCE = "[LEGAL_COMPLIANCE] Create";
export const CREATE_LEGAL_COMPLIANCE_SUCCESS = "[LEGAL_COMPLIANCE] Create Success";
export const CREATE_LEGAL_COMPLIANCE_FAIL = "[LEGAL_COMPLIANCE] Create Fail";
export const CREATE_LEGAL_COMPLIANCE_RESET = "[LEGAL_COMPLIANCE] Create Reset";

export const UPDATE_LEGAL_COMPLIANCE = "[LEGAL_COMPLIANCE] Update";
export const UPDATE_LEGAL_COMPLIANCE_SUCCESS = "[LEGAL_COMPLIANCE] Update Success";
export const UPDATE_LEGAL_COMPLIANCE_FAIL = "[LEGAL_COMPLIANCE] Update Fail";
export const UPDATE_LEGAL_COMPLIANCE_RESET = "[LEGAL_COMPLIANCE] Update Reset";

export const REPLACE_LEGAL_COMPLIANCE = "[LEGAL_COMPLIANCE] Replace";
export const REPLACE_LEGAL_COMPLIANCE_SUCCESS = "[LEGAL_COMPLIANCE] Replace Success";
export const REPLACE_LEGAL_COMPLIANCE_FAIL = "[LEGAL_COMPLIANCE] Replace Fail";
export const REPLACE_LEGAL_COMPLIANCE_RESET = "[LEGAL_COMPLIANCE] Replace Reset";

export const REMOVE_LEGAL_COMPLIANCE = "[LEGAL_COMPLIANCE] Remove";
export const REMOVE_LEGAL_COMPLIANCE_SUCCESS = "[LEGAL_COMPLIANCE] Remove Success";
export const REMOVE_LEGAL_COMPLIANCE_FAIL = "[LEGAL_COMPLIANCE] Remove Fail";

export const LEGAL_COMPLIANCE_SEND_POLICE = "[LEGAL_COMPLIANCE] Send Police";
export const LEGAL_COMPLIANCE_SEND_POLICE_SUCCESS = "[LEGAL_COMPLIANCE] Send Police Success";
export const LEGAL_COMPLIANCE_SEND_POLICE_FAIL = "[LEGAL_COMPLIANCE] Send Police Fail";

export const LEGAL_COMPLIANCE_SEND_PAYTOURIST = "[LEGAL_COMPLIANCE] Send Paytourist";
export const LEGAL_COMPLIANCE_SEND_PAYTOURIST_SUCCESS = "[LEGAL_COMPLIANCE] Send Paytourist Success";
export const LEGAL_COMPLIANCE_SEND_PAYTOURIST_FAIL = "[LEGAL_COMPLIANCE] Send Paytourist Fail";

export const GET_CITY_TAX_REPORT = "[LEGAL_COMPLIANCE] CityTax Report";
export const GET_CITY_TAX_REPORT_SUCCESS = "[LEGAL_COMPLIANCE] CityTax Report Success";
export const GET_CITY_TAX_REPORT_FAIL = "[LEGAL_COMPLIANCE] CityTax Report Fail";

export const LISTEN_LEGAL_COMPLIANCE_UNCONFIRMED = "[LEGAL_COMPLIANCE] Unconfirmed Listen";

export const LEGAL_COMPLIANCE_DOWNLOAD_FILE = "[LEGAL_COMPLIANCE] Download File";

export const LEGAL_COMPLIANCE_CALCULATE_CITY_TAX = "[LEGAL_COMPLIANCE] Calculate City Tax";
export const LEGAL_COMPLIANCE_CALCULATE_CITY_TAX_SUCCESS = "[LEGAL_COMPLIANCE] Calculate City Tax Success";
export const LEGAL_COMPLIANCE_CALCULATE_CITY_TAX_FAIL = "[LEGAL_COMPLIANCE] Calculate City Tax Fail";
