import Vue from "vue";
import Vuex from "vuex";

import app from "./app/reducers";
import auth from "./auth/reducers";
import data from "./data/reducers";
import properties from "./properties/reducers";
import companies from "./companies/reducers";
import clients from "./clients/reducers";
import reservations from "./reservations/reducers";
import reservationSources from './reservation-sources/reducers';
import reservationContracts from './reservation-contracts/reducers';
import settings from './settings/reducers';
import unitCategories from './unit-categories/reducers';
import units from './units/reducers';
import propertyRevenueShares from './property-revenue-shares/reducers';
import propertyRevenueShareRules from './property-revenue-share-rules/reducers';
import propertyCosts from './property-costs/reducers';
import propertyOwners from './property-owners/reducers';
import unitCategoryCosts from './unit-category-costs/reducers';
import users from './users/reducers';
import userPropertyClusters from './user-property-clusters/reducers';
import assets from './assets/reducers';
import workers from './workers/reducers';
import workOrders from "./work-orders/reducers";
import workOrderComments from "./work-order-comments/reducers";
import legalComplianceRequests from './legal-compliance-requests/reducers';
import globalSettings from './global-settings/reducers';
import resources from './resources/reducers';
import resourceEvents from './resource-events/reducers';
import calendar from './calendar/reducers';
import calendarSettings from './calendar-settings/reducers';
import reports from './reports/reducers';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    app,
    auth,
    data,
    companies,
    properties,
    clients,
    reservations,
    reservationSources,
    reservationContracts,
    settings,
    unitCategories,
    units,
    propertyRevenueShares,
    propertyRevenueShareRules,
    propertyCosts,
    propertyOwners,
    unitCategoryCosts,
    users,
    userPropertyClusters,
    assets,
    workers,
    workOrders,
    workOrderComments,
    legalComplianceRequests,
    globalSettings,
    resources,
    resourceEvents,
    calendar,
    calendarSettings,
    reports,
  }
});
