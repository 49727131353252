export const GET_UNIT_CATEGORY_COSTS_LIST = "[UNIT_CATEGORY_COSTS] List Get";
export const GET_UNIT_CATEGORY_COSTS_LIST_SUCCESS = "[UNIT_CATEGORY_COSTS] List Get Success";
export const GET_UNIT_CATEGORY_COSTS_LIST_FAIL = "[UNIT_CATEGORY_COSTS] List Get Fail";

export const GET_UNIT_CATEGORY_COSTS_PAGINATION = "[UNIT_CATEGORY_COSTS] Pagination Get";
export const GET_UNIT_CATEGORY_COSTS_PAGINATION_SUCCESS = "[UNIT_CATEGORY_COSTS] Pagination Get Success";
export const GET_UNIT_CATEGORY_COSTS_PAGINATION_FAIL = "[UNIT_CATEGORY_COSTS] Pagination Get Fail";

export const GET_UNIT_CATEGORY_COSTS_COLLECTION = "[UNIT_CATEGORY_COSTS] Collection Get";
export const GET_UNIT_CATEGORY_COSTS_COLLECTION_SUCCESS = "[UNIT_CATEGORY_COSTS] Collection Get Success";
export const GET_UNIT_CATEGORY_COSTS_COLLECTION_FAIL = "[UNIT_CATEGORY_COSTS] Collection Get Fail";

export const GET_UNIT_CATEGORY_COSTS_SINGLE = "[UNIT_CATEGORY_COSTS] Single Get";
export const GET_UNIT_CATEGORY_COSTS_SINGLE_SUCCESS = "[UNIT_CATEGORY_COSTS] Single Get Success";
export const GET_UNIT_CATEGORY_COSTS_SINGLE_FAIL = "[UNIT_CATEGORY_COSTS] Single Get Fail";

export const CREATE_UNIT_CATEGORY_COSTS = "[UNIT_CATEGORY_COSTS] Create";
export const CREATE_UNIT_CATEGORY_COSTS_SUCCESS = "[UNIT_CATEGORY_COSTS] Create Success";
export const CREATE_UNIT_CATEGORY_COSTS_FAIL = "[UNIT_CATEGORY_COSTS] Create Fail";
export const CREATE_UNIT_CATEGORY_COSTS_RESET = "[UNIT_CATEGORY_COSTS] Create Reset";

export const UPDATE_UNIT_CATEGORY_COSTS = "[UNIT_CATEGORY_COSTS] Update";
export const UPDATE_UNIT_CATEGORY_COSTS_SUCCESS = "[UNIT_CATEGORY_COSTS] Update Success";
export const UPDATE_UNIT_CATEGORY_COSTS_FAIL = "[UNIT_CATEGORY_COSTS] Update Fail";
export const UPDATE_UNIT_CATEGORY_COSTS_RESET = "[UNIT_CATEGORY_COSTS] Update Reset";

export const REMOVE_UNIT_CATEGORY_COSTS = "[UNIT_CATEGORY_COSTS] Remove";
export const REMOVE_UNIT_CATEGORY_COSTS_SUCCESS = "[UNIT_CATEGORY_COSTS] Remove Success";
export const REMOVE_UNIT_CATEGORY_COSTS_FAIL = "[UNIT_CATEGORY_COSTS] Remove Fail";

export const CREATE_UNIT_CATEGORY_COSTS_BULK = "[UNIT_CATEGORY_COSTS] Create Bulk";
export const CREATE_UNIT_CATEGORY_COSTS_BULK_SUCCESS = "[UNIT_CATEGORY_COSTS] Create Bulk Success";
export const CREATE_UNIT_CATEGORY_COSTS_BULK_FAIL = "[UNIT_CATEGORY_COSTS] Create Bulk Fail";
export const CREATE_UNIT_CATEGORY_COSTS_BULK_RESET = "[UNIT_CATEGORY_COSTS] Create Bulk Reset";

export const REGENERATE_UNIT_CATEGORY_COSTS = "[UNIT_CATEGORY_COSTS] Regenerate";
export const REGENERATE_UNIT_CATEGORY_COSTS_SUCCESS = "[UNIT_CATEGORY_COSTS] Regenerate Success";
export const REGENERATE_UNIT_CATEGORY_COSTS_FAIL = "[UNIT_CATEGORY_COSTS] Regenerate Fail";
