import ApiService from "@/services/api";

import crud from "@/store/core/crud";
import list from "@/store/core/list";
import action from "@/store/core/action";

import {
  GET_RESOURCES_LIST,
  GET_RESOURCES_LIST_SUCCESS,
  GET_RESOURCES_LIST_FAIL,
  GET_RESOURCES_PAGINATION,
  GET_RESOURCES_PAGINATION_SUCCESS,
  GET_RESOURCES_PAGINATION_FAIL,
  GET_RESOURCES_COLLECTION,
  GET_RESOURCES_COLLECTION_SUCCESS,
  GET_RESOURCES_COLLECTION_FAIL,
  GET_RESOURCES_SINGLE,
  GET_RESOURCES_SINGLE_SUCCESS,
  GET_RESOURCES_SINGLE_FAIL,
  CREATE_RESOURCES,
  CREATE_RESOURCES_SUCCESS,
  CREATE_RESOURCES_FAIL,
  CREATE_RESOURCES_RESET,
  UPDATE_RESOURCES,
  UPDATE_RESOURCES_SUCCESS,
  UPDATE_RESOURCES_FAIL,
  UPDATE_RESOURCES_RESET,
  REPLACE_RESOURCES,
  REPLACE_RESOURCES_SUCCESS,
  REPLACE_RESOURCES_FAIL,
  REPLACE_RESOURCES_RESET,
  REMOVE_RESOURCES,
  REMOVE_RESOURCES_SUCCESS,
  REMOVE_RESOURCES_FAIL,
} from "./actions";

const listProcessed = list.process({
  getListRequest: {
    action: GET_RESOURCES_LIST,
    endPoint: "resources/list",
    mutationFail: GET_RESOURCES_LIST_FAIL,
    mutationSuccess: GET_RESOURCES_LIST_SUCCESS
  },
});

const crudProcessed = crud.process({
  getPaginationRequest: {
    action: GET_RESOURCES_PAGINATION,
    endPoint: "resources/paginated",
    mutationFail: GET_RESOURCES_PAGINATION_FAIL,
    mutationSuccess: GET_RESOURCES_PAGINATION_SUCCESS
  },
  getCollectionRequest: {
    action: GET_RESOURCES_COLLECTION,
    endPoint: "resources/collected",
    mutationFail: GET_RESOURCES_COLLECTION_FAIL,
    mutationSuccess: GET_RESOURCES_COLLECTION_SUCCESS
  },
  getSingleRequest: {
    action: GET_RESOURCES_SINGLE,
    endPoint: "resources/single",
    mutationFail: GET_RESOURCES_SINGLE_FAIL,
    mutationSuccess: GET_RESOURCES_SINGLE_SUCCESS
  },
  creationRequest: {
    isUpload: true,
    action: CREATE_RESOURCES,
    actionReset: CREATE_RESOURCES_RESET,
    endPoint: "resources/create",
    mutationFail: CREATE_RESOURCES_FAIL,
    mutationSuccess: CREATE_RESOURCES_SUCCESS
  },
  editingRequest: {
    isUpload: true,
    action: UPDATE_RESOURCES,
    actionReset: UPDATE_RESOURCES_RESET,
    endPoint: "resources/update",
    mutationFail: UPDATE_RESOURCES_FAIL,
    mutationSuccess: UPDATE_RESOURCES_SUCCESS
  },
  replaceRequest: {
    action: REPLACE_RESOURCES,
    actionReset: REPLACE_RESOURCES_RESET,
    endPoint: "resources/replace",
    mutationFail: REPLACE_RESOURCES_FAIL,
    mutationSuccess: REPLACE_RESOURCES_SUCCESS
  },
  deletionRequest: {
    action: REMOVE_RESOURCES,
    endPoint: "resources/delete",
    mutationFail: REMOVE_RESOURCES_FAIL,
    mutationSuccess: REMOVE_RESOURCES_SUCCESS
  }
});

const actionsProcessed = action.processMultiple([

]);

const actions = {
  ...listProcessed.actions,
  ...crudProcessed.actions,
  ...actionsProcessed.actions,
};

const getters = {};

const state = {
  getDowntimeRequest: {
    loading: false,
    success: false,
    fail: false,
    data: [],
    errors: {},
  },
  ...listProcessed.state,
  ...crudProcessed.state,
  ...actionsProcessed.state,
};

const mutations = {
  ...listProcessed.mutations,
  ...crudProcessed.mutations,
  ...actionsProcessed.mutations,
};

export default {
  state,
  actions,
  mutations,
  getters
};
