import list from "@/store/core/list";
import crud from "@/store/core/crud";
import action from "@/store/core/action";

import {
  GET_UNITS_LIST,
  GET_UNITS_LIST_SUCCESS,
  GET_UNITS_LIST_FAIL,
  GET_UNITS_PAGINATION,
  GET_UNITS_PAGINATION_SUCCESS,
  GET_UNITS_PAGINATION_FAIL,
  GET_UNITS_COLLECTION,
  GET_UNITS_COLLECTION_SUCCESS,
  GET_UNITS_COLLECTION_FAIL,
  GET_UNITS_SINGLE,
  GET_UNITS_SINGLE_SUCCESS,
  GET_UNITS_SINGLE_FAIL,
  CREATE_UNITS,
  CREATE_UNITS_SUCCESS,
  CREATE_UNITS_FAIL,
  CREATE_UNITS_RESET,
  UPDATE_UNITS,
  UPDATE_UNITS_SUCCESS,
  UPDATE_UNITS_FAIL,
  UPDATE_UNITS_RESET,
  REMOVE_UNITS,
  REMOVE_UNITS_SUCCESS,
  REMOVE_UNITS_FAIL,
  POSITION_UNITS,
  POSITION_UNITS_SUCCESS,
  POSITION_UNITS_FAIL
} from "./actions";

const listProcessed = list.process({
  getListRequest: {
    action: GET_UNITS_LIST,
    endPoint: "units/list",
    mutationFail: GET_UNITS_LIST_FAIL,
    mutationSuccess: GET_UNITS_LIST_SUCCESS
  }
});

const crudProcessed = crud.process({
  getPaginationRequest: {
    action: GET_UNITS_PAGINATION,
    endPoint: "units/paginated",
    mutationFail: GET_UNITS_PAGINATION_FAIL,
    mutationSuccess: GET_UNITS_PAGINATION_SUCCESS
  },
  getCollectionRequest: {
    action: GET_UNITS_COLLECTION,
    endPoint: "units/collected",
    mutationFail: GET_UNITS_COLLECTION_FAIL,
    mutationSuccess: GET_UNITS_COLLECTION_SUCCESS
  },
  getSingleRequest: {
    action: GET_UNITS_SINGLE,
    endPoint: "units/single",
    mutationFail: GET_UNITS_SINGLE_FAIL,
    mutationSuccess: GET_UNITS_SINGLE_SUCCESS
  },
  creationRequest: {
    action: CREATE_UNITS,
    actionReset: CREATE_UNITS_RESET,
    endPoint: "units/create",
    mutationFail: CREATE_UNITS_FAIL,
    mutationSuccess: CREATE_UNITS_SUCCESS
  },
  editingRequest: {
    action: UPDATE_UNITS,
    actionReset: UPDATE_UNITS_RESET,
    endPoint: "units/update",
    mutationFail: UPDATE_UNITS_FAIL,
    mutationSuccess: UPDATE_UNITS_SUCCESS
  },
  deletionRequest: {
    action: REMOVE_UNITS,
    endPoint: "units/delete",
    mutationFail: REMOVE_UNITS_FAIL,
    mutationSuccess: REMOVE_UNITS_SUCCESS
  }
});

const actionsProcessed = action.processMultiple([
  {
    method: 'post',
    name: 'positionRequest',
    action: POSITION_UNITS,
    endPoint: 'units/position/{id}',
    mutations: {
      fail: POSITION_UNITS_FAIL,
      success: POSITION_UNITS_SUCCESS,
    },
  },
]);

const getters = {};

const state = {
  ...listProcessed.state,
  ...crudProcessed.state,
  ...actionsProcessed.state,
};

const actions = {
  ...listProcessed.actions,
  ...crudProcessed.actions,
  ...actionsProcessed.actions,
};

const mutations = {
  ...listProcessed.mutations,
  ...crudProcessed.mutations,
  ...actionsProcessed.mutations,
};

export default {
  state,
  actions,
  mutations,
  getters
};
