import action from "@/store/core/action";

import {
  GET_PRODUCTION_REPORT,
  GET_PRODUCTION_REPORT_SUCCESS,
  GET_PRODUCTION_REPORT_FAIL,
  GET_PRODUCTION_REPORT_RESET,

  GET_REVENUE_SHARE_REPORT,
  GET_REVENUE_SHARE_REPORT_SUCCESS,
  GET_REVENUE_SHARE_REPORT_FAIL,
  GET_REVENUE_SHARE_REPORT_RESET,
} from "./actions";

const actionsProcessed = action.processMultiple([
  {
    name: 'getProductionReportRequest',
    action: GET_PRODUCTION_REPORT,
    actionReset: GET_PRODUCTION_REPORT_RESET,
    endPoint: 'reports/production',
    mutations: {
      fail: GET_PRODUCTION_REPORT_FAIL,
      success: GET_PRODUCTION_REPORT_SUCCESS,
    },
  },
  {
    name: 'getRevenueShareReportRequest',
    action: GET_REVENUE_SHARE_REPORT,
    actionReset: GET_REVENUE_SHARE_REPORT_RESET,
    endPoint: 'reports/revenue-share',
    mutations: {
      fail: GET_REVENUE_SHARE_REPORT_FAIL,
      success: GET_REVENUE_SHARE_REPORT_SUCCESS,
    },
  },
]);

const getters = {};

const state = {
  ...actionsProcessed.state,
};

const actions = {
  ...actionsProcessed.actions,
};

const mutations = {
  ...actionsProcessed.mutations,
};

export default {
  state,
  actions,
  mutations,
  getters
};
