export const GET_UNIT_CATEGORIES_LIST = "[UNITCATEGORIES] List Get";
export const GET_UNIT_CATEGORIES_LIST_SUCCESS = "[UNITCATEGORIES] List Get Success";
export const GET_UNIT_CATEGORIES_LIST_FAIL = "[UNITCATEGORIES] List Get Fail";

export const GET_UNIT_CATEGORIES_PAGINATION = "[UNITCATEGORIES] Pagination Get";
export const GET_UNIT_CATEGORIES_PAGINATION_SUCCESS = "[UNITCATEGORIES] Pagination Get Success";
export const GET_UNIT_CATEGORIES_PAGINATION_FAIL = "[UNITCATEGORIES] Pagination Get Fail";

export const GET_UNIT_CATEGORIES_COLLECTION = "[UNITCATEGORIES] Collection Get";
export const GET_UNIT_CATEGORIES_COLLECTION_SUCCESS = "[UNITCATEGORIES] Collection Get Success";
export const GET_UNIT_CATEGORIES_COLLECTION_FAIL = "[UNITCATEGORIES] Collection Get Fail";

export const GET_UNIT_CATEGORIES_SINGLE = "[UNITCATEGORIES] Single Get";
export const GET_UNIT_CATEGORIES_SINGLE_SUCCESS = "[UNITCATEGORIES] Single Get Success";
export const GET_UNIT_CATEGORIES_SINGLE_FAIL = "[UNITCATEGORIES] Single Get Fail";

export const CREATE_UNIT_CATEGORIES = "[UNITCATEGORIES] Create";
export const CREATE_UNIT_CATEGORIES_SUCCESS = "[UNITCATEGORIES] Create Success";
export const CREATE_UNIT_CATEGORIES_FAIL = "[UNITCATEGORIES] Create Fail";
export const CREATE_UNIT_CATEGORIES_RESET = "[UNITCATEGORIES] Create Reset";

export const UPDATE_UNIT_CATEGORIES = "[UNITCATEGORIES] Update";
export const UPDATE_UNIT_CATEGORIES_SUCCESS = "[UNITCATEGORIES] Update Success";
export const UPDATE_UNIT_CATEGORIES_FAIL = "[UNITCATEGORIES] Update Fail";
export const UPDATE_UNIT_CATEGORIES_RESET = "[UNITCATEGORIES] Update Reset";

export const REMOVE_UNIT_CATEGORIES = "[UNITCATEGORIES] Remove";
export const REMOVE_UNIT_CATEGORIES_SUCCESS = "[UNITCATEGORIES] Remove Success";
export const REMOVE_UNIT_CATEGORIES_FAIL = "[UNITCATEGORIES] Remove Fail";

