export const GET_UNITS_LIST = "[UNITS] List Get";
export const GET_UNITS_LIST_SUCCESS = "[UNITS] List Get Success";
export const GET_UNITS_LIST_FAIL = "[UNITS] List Get Fail";

export const GET_UNITS_PAGINATION = "[UNITS] Pagination Get";
export const GET_UNITS_PAGINATION_SUCCESS = "[UNITS] Pagination Get Success";
export const GET_UNITS_PAGINATION_FAIL = "[UNITS] Pagination Get Fail";

export const GET_UNITS_COLLECTION = "[UNITS] Collection Get";
export const GET_UNITS_COLLECTION_SUCCESS = "[UNITS] Collection Get Success";
export const GET_UNITS_COLLECTION_FAIL = "[UNITS] Collection Get Fail";

export const GET_UNITS_SINGLE = "[UNITS] Single Get";
export const GET_UNITS_SINGLE_SUCCESS = "[UNITS] Single Get Success";
export const GET_UNITS_SINGLE_FAIL = "[UNITS] Single Get Fail";

export const CREATE_UNITS = "[UNITS] Create";
export const CREATE_UNITS_SUCCESS = "[UNITS] Create Success";
export const CREATE_UNITS_FAIL = "[UNITS] Create Fail";
export const CREATE_UNITS_RESET = "[UNITS] Create Reset";

export const UPDATE_UNITS = "[UNITS] Update";
export const UPDATE_UNITS_SUCCESS = "[UNITS] Update Success";
export const UPDATE_UNITS_FAIL = "[UNITS] Update Fail";
export const UPDATE_UNITS_RESET = "[UNITS] Update Reset";

export const REMOVE_UNITS = "[UNITS] Remove";
export const REMOVE_UNITS_SUCCESS = "[UNITS] Remove Success";
export const REMOVE_UNITS_FAIL = "[UNITS] Remove Fail";

export const POSITION_UNITS = "[UNITS] Position";
export const POSITION_UNITS_SUCCESS = "[UNITS] Position Success";
export const POSITION_UNITS_FAIL = "[UNITS] Position Fail";
